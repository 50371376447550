import * as React from "react"

import defaultImage from "../images/hero-default.jpg"
import { siteMetadata } from "../../gatsby-config"

const Seo = ({
    title = null,
    description = null,
    path = null,
    image = null,
    children
}) => {

    const siteUrl = siteMetadata.siteUrl;
    const defaultTitle = siteMetadata.title;
    const defaultDescription = "D3VL - Software That Rocks | Get ahead of the competition with bespoke software tailored to your specific needs. We offer a wide range of services, Discover more on our website."

    const seo = {
        title: title || defaultTitle,
        canonical: path ? `${siteUrl}${path}` : null,
        metaImage: (image && image.startsWith("/")) ? `${siteUrl}${image}` : `${siteUrl}${defaultImage}`,
        description: description || defaultDescription
    }

    const renderTitle = () => {
        if (seo.title !== null) {
            return (<>
                <title>{seo.title}</title>
                <meta property="og:image:alt" content={seo.title} />
                <meta name="twitter:title" content={seo.title} />
                <meta name="twitter:image:alt" content={seo.title} />
                <meta itemProp="name" content={seo.title} />
                <meta property="og:title" content={seo.title} />
            </>)
        } else return null
    }

    const renderDescription = () => {
        if (seo.description !== null) {
            return (<>
                <meta name="description" content={seo.description} />
                <meta name="twitter:description" content={seo.description} />
                <meta itemProp="description" content={seo.description} />
                <meta property="og:description" content={seo.description} />
            </>)
        } else return null
    }

    const renderCanonical = () => {
        if (seo.canonical !== null) {
            return (<>
                <link rel="canonical" href={seo.canonical} />
                <meta property="og:url" content={seo.canonical} />
            </>)
        } else return null
    }

    const renderMetaImage = () => {
        if (seo.metaImage !== null) {
            return (<>
                <meta name="twitter:image" content={seo.metaImage} />
                <meta property="og:image:secure_url" content={seo.metaImage} />
                <meta itemProp="image" content={seo.metaImage} />
                <meta property="og:image" content={seo.metaImage} />
                <meta property="og:image:type" content="image/jpeg" />
                <meta name="twitter:card" content="summary_large_image" />
            </>)
        } else return null
    }

    return (
        <>
            {/* Critical CSS to get the colors in quick */}
            <style>{`html {background-color: rgb(33, 33, 33);}`}</style>

            {renderTitle()}
            {renderDescription()}
            {renderCanonical()}
            {renderMetaImage()}

            {/* misc */}
            <meta property="og:type" content="website" />
            <meta name="twitter:site" content="@d3vlsoftware" />
            <meta name="twitter:creator" content="@d3vlsoftware" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <meta name="theme-color" content="#ff3e3e" />

            {children}

            <meta name="easter-egg" content="You Found Easter Egg 3 of 6" />
        </>
    )
}

export default Seo

