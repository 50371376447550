import * as React from "react"

import globeStill from '../images/globe-still-square.png'

const globeStyle = {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundImage: `url('${globeStill}')`,
    backgroundSize: '86.4%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100vh',
    height: '100vh'
}

const globeCanvasStyle = {
    with: '100%',
    height: '100%',
}


const Globe = () => {

    const globeContainer = React.useRef(null);
    const globeCanvas = React.useRef(null);

    React.useEffect(() => {
        let worker = null;

        const mouseEventHandler = makeSendPropertiesHandler([
            'ctrlKey',
            'metaKey',
            'shiftKey',
            'button',
            'pointerType',
            'clientX',
            'clientY',
            'pageX',
            'pageY',
        ]);

        // const workerReady = w => new Promise(r => w.addEventListener("message", r, { once: true }));

        function makeSendPropertiesHandler(properties) {
            return function sendProperties(event, sendFn) {
                const data = { type: event.type };
                for (const name of properties) {
                    data[name] = event[name];
                }
                sendFn(data);
            };
        }

        function touchEventHandler(event, sendFn) {
            const touches = [];
            const data = { type: event.type, touches };
            for (let i = 0; i < event.touches.length; ++i) {
                const touch = event.touches[i];
                touches.push({
                    pageX: touch.pageX,
                    pageY: touch.pageY,
                });
            }
            sendFn(data);
        }

        let nextProxyId = 0;
        class ElementProxy {
            constructor(element, worker, eventHandlers) {
                this.id = nextProxyId++;
                this.worker = worker;
                const sendEvent = (data) => {
                    this.worker.postMessage({
                        type: 'event',
                        id: this.id,
                        data,
                    });
                };

                // register an id
                worker.postMessage({
                    type: 'makeProxy',
                    id: this.id,
                });

                // sendSize();

                for (const [eventName, handler] of Object.entries(eventHandlers)) {
                    element.addEventListener(eventName, function (event) {
                        handler(event, sendEvent);
                    }, {
                        passive: true,
                    });
                }

                const fake = {
                    type: 'NULL',
                    ctrlKey: false,
                    metaKey: false,
                    shiftKey: false,
                    button: 0,
                    pointerType: 'mouse',
                    clientX: 0,
                    clientY: 0,
                    pageX: 0,
                    pageY: 0,
                }

                // fake a mouseup event when the window loses focus or the mouse leaves the element to prevent the globe from getting stuck in a dragging state
                window.addEventListener('blur', () => {
                    sendEvent({ ...fake, type: 'mouseup' });
                    sendEvent({ ...fake, type: 'pointerup' });
                });

                element.addEventListener('mouseleave', () => {
                    sendEvent({ ...fake, type: 'mouseup' });
                    sendEvent({ ...fake, type: 'pointerup' });
                });


                function onWindowResize() {
                    worker.postMessage({
                        type: 'onWindowResize',
                        newInnerWidth: window.innerWidth,
                        newInnerHeight: window.innerHeight
                    });
                    const rect = element.getBoundingClientRect();
                    sendEvent({
                        type: 'size',
                        left: rect.left,
                        top: rect.top,
                        width: element.clientWidth,
                        height: element.clientHeight,
                    });
                }

                window.addEventListener("resize", onWindowResize, false);
                onWindowResize();
            }
        }

        async function startOnWorkerThread(canvas, offscreen) {
            canvas.focus();
            // const offscreen = canvas.transferControlToOffscreen();
            worker = new Worker('/globe-sw.js', { type: 'module' });

            const eventHandlers = {
                pointerdown: mouseEventHandler,
                pointermove: mouseEventHandler,
                pointerup: mouseEventHandler,
                pointercancel: mouseEventHandler,
                ondragstart: mouseEventHandler,
                touchstart: touchEventHandler,
                touchmove: touchEventHandler,
                touchend: touchEventHandler,
            };
            const proxy = new ElementProxy(canvas, worker, eventHandlers);

            worker.postMessage({
                type: 'startWithProxy',
                canvas: offscreen,
                canvasId: proxy.id,
                windowInnerWidth: window.innerWidth,
                windowInnerHeight: window.innerHeight,
                devicePixelRatio: window.devicePixelRatio
            }, [offscreen]);


            const globeData = await fetch('/api/globe-data').then((res) => {
                return res.json();
            })

            worker.postMessage({
                type: 'globeProxy',
                globeFn: 'ringsData',
                args: [globeData.you]
            });

            worker.postMessage({
                type: 'globeProxy',
                globeFn: 'arcsData',
                args: [globeData.users]
            });

            // console.log('using OffscreenCanvas');
        }

        function startOnMainThread() {
            // console.log('using regular canvas');
            // convert #globe-sw-preload preload to script tag, and start on main thread
            const preload = document.querySelector('#globe-sw-preload');
            const src = preload ? preload.getAttribute('href') : '/globe-sw.js';

            const script = document.createElement('script');
            script.setAttribute('src', src);

            // insert into head
            document.querySelector('head').appendChild(script);
        }

        window['getGlobeCanvas'] = () => {
            return globeCanvas.current;
        }

        // START GLOBE
        // const domContainer = globeContainer.current;

        const canvas = globeCanvas.current;
        if (canvas.transferControlToOffscreen) {
            try {
                const offscreen = canvas.transferControlToOffscreen();
                startOnWorkerThread(canvas, offscreen);
            } catch (e) {
                // if error, duplicate canvas, replace old one with new one, and try again
                const newCanvas = canvas.cloneNode();
                canvas.parentNode.replaceChild(newCanvas, canvas);
                globeCanvas.current = newCanvas;

                const offscreen = newCanvas.transferControlToOffscreen();
                startOnWorkerThread(newCanvas, offscreen);
            }
        } else {
            startOnMainThread();
        }

        return function cleanup() {
            // remove worker
            if (worker) worker.terminate();
        }
    });

    return (
        <div ref={globeContainer} style={globeStyle} className="globe-container">
            <canvas ref={globeCanvas} style={globeCanvasStyle} />
        </div>
    )
}

export default Globe