import * as React from "react"

// usage example:
// <TurnstileFormInput siteKey="0x00000000000000000000" />

const TurnstileFormInput = ({ siteKey }) => {

    const instance = React.useRef(null);
    const [isTurnstileReady, setTurnstileReady] = React.useState(false);

    React.useEffect(() => {

        // check if we've loaded the component before in the app lifecycle
        if (!window.turnstileReady) {

            // create a deferred promise that will resolve when turnstile is loaded
            window.turnstileDefer = {};
            window.turnstileReady = new Promise((resolve, reject) => {
                window.turnstileDefer.resolve = resolve;
                window.turnstileDefer.reject = reject;
            });

            // callback for turnstile to call when it's loaded
            window.__ON_TURNSTILE_LOAD__ = (err) => {
                (err) ? window.turnstileDefer.reject() : window.turnstileDefer.resolve();
            }

            // check if turnstile has been loaded before in the app lifecycle
            if (window.turnstile) {
                // if it has, resolve the promise
                window.turnstileDefer.resolve();
            } else {
                // if it hasn't, load it
                const script = document.createElement("script");
                script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=__ON_TURNSTILE_LOAD__";
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);
            }
        }

        // wait for turnstile to be loaded, then render the widget
        (async () => {
            await window.turnstileReady;
            setTurnstileReady(true);
        })();

        // when the component unmounts, remove the turnstile instance
        return () => {
            if (instance.current) window.turnstile.remove(instance.current);
        };
    }, []);

    // after widget is in the DOM, initialize it with turnstile.render
    const ref = React.useCallback((node) => {
        if (!node) return;
        instance.current = window.turnstile.render(node, { siteKey });
    }, [siteKey]);


    return (
        <>
            {isTurnstileReady && (<div ref={ref} data-theme="dark" className="cf-turnstile" data-sitekey={siteKey} />)}
        </>
    );

}

export default TurnstileFormInput