import * as React from "react"
import { Link } from "gatsby"

const CallToAction = ({ text }) => {
    const c2a = text || "LAUNCH YOUR PROJECT WITH US";

    return (
        <section className="mt-5 text-center container  d-print-none">
            <div data-augmented-ui="border tr-2-clip-x  bl-2-clip-x  tl-clip br-clip b-clip-x" id="launch_with_us_cta" className="p-5">
                <h2 className="display-6">{c2a}</h2>
                <Link to="/contact-us" data-augmented-ui="br-clip border inlay" className="btn btn-lg text-white fill-bg px-5 mt-2">Contact Us</Link>
            </div>
        </section>
    )
}

export default CallToAction