import * as React from "react"
import { Link } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CaseStudyCard = ({
    ...CaseStudy
}) => {

    // const imageStyle = {
    //     maxHeight: "200px",
    //     objectFit: "cover"
    // }

    // console.log("CaseStudy", CaseStudy)

    const image = getImage(CaseStudy.image)

    return (<>
        <div className="col-12 col-lg-6 col">
            <article className="row m-3 text-danger bg-tinted case-study-ui-card" data-augmented-ui="border tr-2-clip-x tl-2-clip-y br-clip">
                <div className="col-12 text-warning p-0">
                    <figure>
                        <GatsbyImage image={image} />
                    </figure>
                </div>
                <div className="col-12 px-4  text-white pb-5">
                    <h3 className="mb-0">{CaseStudy.title}</h3>
                    <small className="text-muted">{new Date(CaseStudy.createdAt).toLocaleDateString('en-GB', { year: 'numeric', month: 'long' })}</small>
                    <p>{CaseStudy.description}</p>
                    <Link to={`/case-study/${CaseStudy.slug}`} data-augmented-ui="br-clip border inlay" className="btn btn-lg text-warning fill-bg px-5 br-clip-absolute-btn w-100">Read More</Link>
                </div>
            </article>
        </div>
    </>)
}

export default CaseStudyCard