import * as React from "react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Nav = () => {

    // register event listener for click on the hamburger menu
    const toggleMenu = () => {
        const menu = document.querySelector('.navbar-collapse');
        menu.classList.toggle('show');
    }

    const isSubPage = (parent) => ({ location, isCurrent }) => (location.pathname.includes(parent) || isCurrent) ? { className: "nav-link active" } : {}

    return (
        <header>

            <nav className="navbar d-print-none fixed-top navbar-expand-lg navbar-dark bg-enabled">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <StaticImage placeholder="none" src="../images/logo.png" alt="D3VL Logo - Go Home" title="Home" height={40} />
                    </Link>


                    <button data-augmented-ui="border br-clip tl-clip " onClick={toggleMenu} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse  my-3" id="navbarSupportedContent">
                        <ul className="navbar-nav ui-list-nav">

                            <li className="nav-item mx-4">
                                <Link to="/" className="nav-link" activeClassName="active">Home</Link>
                            </li>

                            <li className="nav-item mx-4">
                                <AnchorLink to="/#who-are-we" className="nav-link" activeClassName="active">Who Are We</AnchorLink>
                            </li>

                            <li className="nav-item mx-4">
                                <Link to="/how-we-work" className="nav-link" activeClassName="active">How We Work</Link>
                            </li>

                            <li className="nav-item mx-4">
                                <Link to="/case-studies" className="nav-link" activeClassName="active" getProps={isSubPage('/case-study')}>Case Studies</Link>
                            </li>

                            <li className="nav-item mx-4">
                                <Link to="/blog" className="nav-link" activeClassName="active" getProps={isSubPage('/blog')}>Blog</Link>
                            </li>
                        </ul>
                        <div className="d-lg-none mx-4 mt-3">
                            <Link to="/contact-us" data-augmented-ui="br-clip tl-clip border inlay" className="btn text-white px-5 w-100 fill-bg">Contact Us</Link>
                        </div>
                    </div>

                    <div className="float-end d-none d-lg-block">
                        <Link to="/contact-us" data-augmented-ui="br-clip border inlay" className="btn text-white px-5 fill-bg">Contact Us</Link>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Nav