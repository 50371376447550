import * as React from "react"


const WebsiteSurvey = (props) => {
    const FORM_ENDPOINT = "/api/website-survey-response"

    const [page, setPage] = React.useState('requirement');
    const [previous, setPrevious] = React.useState([]);

    const results = React.useRef({});

    const optionClick = async (e) => {
        console.log(e);
        let val = e.currentTarget.getAttribute('data-value');
        let next = e.currentTarget.getAttribute('data-next');

        if (next === 'exit') {
            typeof props?.onExit === 'function' && props.onExit({ success: false });
            return;
        }

        if (next === 'done') {
            next = await doSubmit();
        }

        results.current[page] = val;

        setPrevious([...previous, page]);
        setPage(next);

        console.log(results);
    }

    const optionSet = (val, key) => {
        results.current[key] = val;
    }

    const generateOverview = () => {
        let text = "";
        const _ = results.current;

        if (_?.requirement === 'Make Changes') {
            text += "You are looking to make changes to your existing website ";


            if (_['current-stack'] === "Not sure") {
                text += "but you are not sure what your current website is built with. \r";
            } else if (_['current-stack'] === "Custom Built") {
                text += "that uses a Custom Built CMS. \r";
            } else {
                text += "that is built with " + _['current-stack'] + ". \r";
            }
        } else {
            text += "You are looking for a new website";

            if (_['site-type'] === "To Advertise my business") {
                text += " to advertise your business. \r";
            } else if (_['site-type'] === "To sell my products / services") {
                text += " to sell your products or services. \r";
            } else {
                text += ". \r";
            }

        }


        if (_['business-type'] !== "Other" && _['business-type'] !== "Personal") {
            text += "You are a " + _['business-type'];
        } else if (_['business-type'] === "Personal") {
            text += "You are a private individual";
        } else {
            text += "You are";
        }


        if (_['business-industry'] !== undefined && _['business-industry'] !== "Other") {
            text += " working in the " + _['business-industry'] + " industry ";
        }

        if (_['time-frame'] !== "not sure") {
            text += " and require your " + ((_['requirement'] === 'Make Changes') ? 'website updates' : 'new website') + " " + _['time-frame'];
        } else {
            text += "";
        }

        if (_['budget'] !== "not sure") {
            text += " with a budget of " + _['budget'] + ". \r";
        }

        return text;
    }

    const doSubmit = async () => {
        const json = results.current

        fetch(FORM_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify(json),
            headers: { 'Content-Type': 'application/json' }
        }).then(async response => {

            if (!response.ok) {
                return Promise.resolve("error")
            }

            const json = await response.json();
            if (json?.success) {
                return Promise.resolve("done")
            } else {
                return Promise.resolve("error")
            }

        }).catch(err => {
            return Promise.resolve("error")
        })
    }

    const btnClasses = "border border-danger p-3 py-1 text-center cursor-pointer bg-tinted"

    return (
        <>


            <div className="row">
                {
                    props?.showExit &&
                    <>
                        <div className="m-1 position-absolute top-0 end-0 cursor-pointer border-danger w-auto" data-value="null" data-next="exit" onClick={optionClick} onKeyDown={optionClick} role="presentation">
                            <small>close</small>
                        </div>
                    </>
                }

                {page === 'requirement' && <>

                    <div className="col-12 mb-3">
                        <h5 className="text-center">What Service do you require?</h5>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                        <div className="border border-danger p-3 py-4 text-center cursor-pointer bg-tinted position-relative d-flex flex-column justify-content-center h-100" data-value="New Website" data-next="site-type" onClick={optionClick} onKeyDown={optionClick} role="presentation">
                            <h5 className="card-title">New Website</h5>
                            <p className="card-text">I need a new website for my business.</p>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 mb-3">
                        <div className="border border-danger p-3 py-4 text-center cursor-pointer bg-tinted d-flex flex-column justify-content-center h-100" data-value="Make Changes" data-next="current-stack" onClick={optionClick} onKeyDown={optionClick} role="presentation" >
                            <h5 className="card-title">Website Changes</h5>
                            <p className="card-text">I want to make changes to my existing website.</p>
                        </div>
                    </div>

                    {
                        props?.showExit &&
                        <>
                            <div className="col-12">
                                <div className="border border-danger p-2 py-2 text-center cursor-pointer bg-tinted" data-value="null" data-next="exit" onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                    <p className="card-title">I'd just like to read the page!</p>
                                </div>
                            </div>
                        </>
                    }

                </>}

                {
                    page === 'site-type' && <>
                        <div className="col-12 mb-3">
                            <h5 className="text-center">What is your website for?</h5>
                        </div>
                        {
                            [
                                ['To Advertise my business', 'business-type'],
                                ['To sell my products / services', 'business-type'],
                                ['Something Else', 'business-type']
                            ].map((item, index) => {
                                return (
                                    <div className="col-12 mb-2">
                                        <div className={btnClasses} data-value={item[0]} data-next={item[1]} onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                            <p className="card-text">{item[0]}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                }

                {
                    page === 'current-stack' && <>
                        <div className="col-12 mb-3">
                            <h5 className="text-center">What platform does your website use?</h5>
                        </div>
                        {
                            [
                                ['Custom Built', 'business-type'],
                                ['WordPress', 'business-type'],
                                ['Wix', 'business-type'],
                                ['Squarespace', 'business-type'],
                                ['Shopify', 'business-type'],
                                ['OpenCart', 'business-type'],
                                ['GoDaddy', 'business-type'],
                                ['Not sure', 'business-type'],

                            ].map((item, index) => {
                                return (
                                    <div className="col-12 col-sm-6 mb-2">
                                        <div className={btnClasses} data-value={item[0]} data-next={item[1]} onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                            <p className="card-text">{item[0]}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                }

                {
                    page === 'business-type' && <>

                        <div className="col-12 mb-3">
                            <h5 className="text-center">What type of business are you?</h5>
                        </div>

                        {
                            [
                                ['Private Individual', 'business-industry'],
                                ['Sole Trader', 'business-industry'],
                                ['Self Employed', 'business-industry'],
                                ['Small/Local Business', 'business-industry'],
                                ['National Business', 'business-industry'],
                                ['International Business', 'business-industry'],
                                ['Educational / School', 'time-frame'],
                                ['Government', 'time-frame'],
                                ['Charity / Not for Profit', 'business-industry'],
                                ['Other', 'business-industry']
                            ].map((item, index) => {
                                return (
                                    <div className="col-12 col-sm-6  mb-2">
                                        <div className={btnClasses} data-value={item[0]} data-next={item[1]} onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                            <p className="card-text">{item[0]}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </>
                }

                {
                    page === 'business-industry' && <>

                        <div className="col-12 mb-3">
                            <h5 className="text-center">What industry do you operate in?</h5>
                        </div>

                        {
                            [
                                ['Business Services', 'time-frame'],
                                ['Construction', 'time-frame'],
                                ['Creative', 'time-frame'],
                                ['Entertainment', 'time-frame'],
                                ['Financial', 'time-frame'],
                                ['Health & Fitness', 'time-frame'],
                                ['Restaurant/Food', 'time-frame'],
                                ['Retail', 'time-frame'],
                                ['Technology', 'time-frame'],
                                ['Other', 'time-frame'],
                            ].map((item, index) => {
                                return (
                                    <div className="col-12 col-sm-6 mb-2">
                                        <div className={btnClasses} data-value={item[0]} data-next={item[1]} onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                            <p className="card-text">{item[0]}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </>
                }

                {
                    page === 'time-frame' && <>


                        <div className="col-12 mb-3">
                            <h5 className="text-center">What's your time frame?</h5>
                        </div>
                        <div className="col-12 mb-2">
                            <div className={btnClasses} data-value="ASAP" data-next="budget" onClick={optionClick} onKeyDown={optionClick} role="presentation" >
                                <p className="card-text">ASAP</p>
                            </div>
                        </div>
                        {
                            [
                                // ['ASAP!', 'budget'],
                                ['within a few weeks', 'budget'],
                                ['within a month', 'budget'],
                                ['within a few months', 'budget'],
                                ['not sure', 'budget'],
                            ].map((item, index) => {
                                return (
                                    <div className="col-12 col-sm-6 mb-2">
                                        <div className={btnClasses} data-value={item[0]} data-next={item[1]} onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                            <p className="card-text">{item[0]}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </>
                }

                {
                    page === 'budget' && <>
                        <div className="col-12 mb-3">
                            <h5 className="text-center">What's your budget?</h5>
                        </div>
                        {
                            [
                                ['less than £250', 'overview'],
                                ['£250 - £1000', 'overview'],
                                ['£1000 - £2500', 'overview'],
                                ['£2500 - £5000', 'overview'],
                                ['£5000 or more', 'overview'],
                                ['not sure', 'overview'],
                            ].map((item, index) => {
                                return (
                                    <div className="col-12 col-sm-6 mb-2">
                                        <div className={btnClasses} data-value={item[0]} data-next={item[1]} onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                            <p className="card-text">{item[0]}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                }

                {
                    page === 'overview' && <>
                        <div className="col-12 mb-3 text-center">
                            <h5>Overview</h5>
                            <p >
                                {generateOverview()}
                            </p>
                            <p>
                                <b>Is this correct?</b>
                            </p>
                        </div>

                        <div className="col-12 col-sm-6 mb-3">
                            <div className={btnClasses} data-value="No" data-next="requirement" onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                <p className="card-text">No, Restart</p>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 mb-3">
                            <div className={btnClasses} data-value="Yes" data-next="contact" onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                <p className="card-text">Yes</p>
                            </div>
                        </div>

                    </>
                }

                {
                    page === 'contact' && <>
                        <div className="col-12 mb-3">
                            <h5 className="text-center">What's Your Email?</h5>
                        </div>

                        <div className="mb-4 ui-input-group">
                            <div className="input-group-input text-danger border border-danger ">
                                <input type="text" className="form-control" placeholder="example@company.com" aria-label="Email Address" aria-describedby="input-email" name="email" required maxLength={70} onChange={(e) => { optionSet(e.target.value, 'email') }} />
                            </div>
                        </div>

                        <div className="col-12 mb-2">
                            <div className={btnClasses} data-value="submit" data-next="done" onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                <p className="card-text">Next</p>
                            </div>
                        </div>

                    </>
                }

                {
                    page === 'done' && <>
                        <div className="col-12 mb-3">
                            <h5 className="text-center">Success!</h5>
                        </div>

                        <div className="col-12 mb-2 text-center">
                            <p>
                                Thank you for your interest in our services<br />
                                We will be in touch shortly.
                            </p>
                        </div>

                        {props?.showExit && <>
                            <div className="col-12 mb-2 text-center">
                                <div className={btnClasses} data-value="exit" data-next="exit" onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                    <p className="card-text">Close</p>
                                </div>
                            </div>
                        </>}


                    </>
                }

                {
                    page === 'error' && <>
                        <div className="col-12 mb-3">
                            <h5 className="text-center">Oops!</h5>
                        </div>

                        <div className="col-12 mb-2 text-center">
                            <p>
                                Sorry, there has been an error processing your responses. <br />
                                Please email us instead! <a href="mailto:hello@d3vl.com">hello@d3vl.com</a>
                            </p>
                        </div>

                        {props?.showExit && <>
                            <div className="col-12 mb-2 text-center">
                                <div className={btnClasses} data-value="exit" data-next="exit" onClick={optionClick} onKeyDown={optionClick} role="presentation">
                                    <p className="card-text">Close</p>
                                </div>
                            </div>
                        </>}


                    </>
                }

            </div >
            {/* </div>

                    </div>
                </div>
            </div> */}
        </>
    )
}

export default WebsiteSurvey