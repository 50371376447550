/* layouts/index.js */

import React from "react"

import { Nav, Footer } from '../components'
import "../styles/vendor.scss"
import "../styles/main.scss"
const Layout = ({ children, location }) => {

    return (
        <>
            <Nav />
            {children}
            <Footer />
        </>
    )
}

export default Layout