/**
 * @type {import('gatsby').GatsbyConfig}
 */

module.exports = {
    siteMetadata: {
        title: `D3VL - Software That Rocks`,
        siteUrl: `https://d3vl.com`
    },
    plugins: [
        "gatsby-plugin-image",
        "gatsby-plugin-sitemap",
        "gatsby-plugin-sharp",
        "gatsby-plugin-sass",
        "gatsby-transformer-json",
        "gatsby-plugin-sitemap",
        "gatsby-plugin-layout",
        {
            resolve: "gatsby-transformer-remark",
            options: {
                plugins: [
                    "gatsby-remark-copy-linked-files",
                    "gatsby-remark-relative-images",
                    "gatsby-remark-images",
                    "gatsby-remark-prismjs",
                    "gatsby-remark-external-links",
                ],
            },
        },
        {
            resolve: "gatsby-transformer-sharp",
            options: {
                defaults: {
                    formats: [`auto`, `webp`, `png`, `jpg`],
                    placeholder: `none`,
                    backgroundColor: `transparent`,
                }
            }
        },
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                "name": "images",
                "path": "./src/images/",
                "ignore": [
                    // ignore psd files
                    "**/*.psd",
                    // ignore svg files
                    "**/*.svg"
                ]
            },
            __key: "images"
        },
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                "name": "pages",
                "path": "./src/pages/"
            },
            __key: "pages"
        },
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                "name": "caseStudies",
                "path": "./src/pages/case-study/",
            },
            __key: "caseStudies"
        },
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                "name": "blogPosts",
                "path": "./src/content/blog",
            },
            __key: "blogPosts"
        },
        {
            resolve: "gatsby-plugin-anchor-links",
            options: { offset: -100 }
        }
    ]
};