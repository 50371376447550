import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { graphql, useStaticQuery } from "gatsby"

const Footer = () => {
    const footerStyle = {
        '--bs-bg-opacity': 0.3,
    }

    const year = new Date().getFullYear()

    // get last 3 blog posts for footer
    const { allMarkdownRemark } = useStaticQuery(graphql`
        query {
            allMarkdownRemark(
                sort: { frontmatter:{ date: DESC } }
                filter: { frontmatter: { published: { eq: true } } }
                limit: 3
            ) {
                edges {
                    node {
                        frontmatter {
                            title
                            slug
                        }
                    }
                }
            }
        }
    `)

    const blogPosts = allMarkdownRemark.edges

    return (
        <section className="mt-5 p-4 bg-black d-print-none" style={footerStyle}>
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-12 col-3 col-md-4 col-lg-2 px-auto px-lg-5 mb-2 mb-lg-0 text-center">
                        <StaticImage src="../images/transparent-red.png" placeholder="none" alt="logo" height={90} />
                        <br />
                        <small className="text-muted px-0">&copy; D3VL {year}</small>
                    </div>


                    <div className="col-12 col-md-4 col-lg-2  d-none d-md-block">
                        <h6 className="text-white">Links</h6>
                        <ul className="list-unstyled text-small ui-list">
                            <li><a target="_blank" rel="noreferrer" className="link-secondary" href="https://github.com/d3vl">GitHub</a></li>
                            <li><a target="_blank" rel="noreferrer" className=" link-secondary" href="https://www.linkedin.com/company/d3vl">LinkedIn</a></li>
                            <li><Link to="/service/web-development-in-bexhill/" className="link-secondary">Local Web Development</Link></li>
                        </ul>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2 d-none d-md-block">
                        <h6 className="text-white">Legal</h6>
                        <ul className="list-unstyled text-small ui-list">
                            <li><a className="link-secondary" href="#">Privacy Policy</a></li>
                            <li><a className="link-secondary" href="#">Terms of Service</a></li>
                        </ul>
                    </div>


                    <small className="col-12 col-md-12 col-lg-3 d-none d-lg-block">
                        <b>From The Blog</b>
                        <ul className="list-unstyled text-small ui-list">
                            {blogPosts.map((post, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={`/blog/${post.node.frontmatter.slug}`} className="link-secondary">
                                            {/* title truncated to 40 chars */}
                                            {
                                                (
                                                    post.node.frontmatter.title.length > 40 ?
                                                        post.node.frontmatter.title.substring(0, 40) + "..." :
                                                        post.node.frontmatter.title
                                                ).replace(/\[(.*?)\]/g, '$1')

                                            }
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </small>
                </div>

            </div>
        </section >
    )
}

export default Footer