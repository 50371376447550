import * as React from "react"

const CaseStudySideCard = ({
    lists,
    children
}) => {

    const renderLists = (lists) => {
        if (!lists) return null;
        return lists.map((list) => {
            const { title, items } = list;
            return (
                <div className="col-12 col-md-6 col-lg-12 cs-side-card">
                    <h5 className="ms-2">{title}</h5>
                    <ul className="ui-list">
                        {
                            items.map((item) => {
                                return <li key={item}>{item}</li>
                            })
                        }
                    </ul>
                </div>
            )
        });
    }

    return (
        <section className="ui-card p-4 mx-3 row" data-augmented-ui="border tl-clip br-clip">
            {renderLists(lists)}
            <div className="col-12 d-print-none">
                {children}
            </div>
        </section>
    )
}

export default CaseStudySideCard