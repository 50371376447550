export function onRouteUpdate({ location, prevLocation }) {
    // close menu on route change
    const menu = document.querySelector('.navbar-collapse');
    if (menu.classList.contains('show')) {
        menu.classList.remove('show');
    }

    if (location.hash) {
        const item = document.querySelector(location.hash)
        if (item) return;
    }

    if (location.pathname !== prevLocation?.pathname) {
        window.scrollTo(0, 0)
    }


}
