import * as React from "react"
import { Link } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogPostCard = ({
    ...BlogPost
}) => {

    // console.log("BlogPost", BlogPost)

    const image = getImage(BlogPost.frontmatter.hero)

    const title = BlogPost.frontmatter.title.replace(/\[(.*?)\]/g, '<span class="text-danger">$1</span>');

    return (<>
        <div className="col-12">
            <article className="row m-3 text-danger bg-tinted d-block d-md-flex flex-row" data-augmented-ui="border tr-2-clip-x tl-2-clip-y br-clip">
                <div className="col-12 col-lg-3 text-warning p-0">
                    <figure className="m-0 h-100" style={{ height: "calc(100% - 2px) !important" }}>
                        <GatsbyImage className="h-100 w-100" image={image} />
                    </figure>
                </div>
                <div className="col-12 col-lg-9 p-4 text-white pb-4">
                    <h3 className="mb-0" dangerouslySetInnerHTML={{ __html: title }}></h3>
                    <small className="text-muted">
                        {new Date(BlogPost.frontmatter.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' })} | {BlogPost.timeToRead} min read
                    </small>
                    <p>
                        {BlogPost.frontmatter.description}
                        <br />
                        <code className="text-danger">
                            {BlogPost.excerpt}
                        </code>
                    </p>
                    <Link to={`/blog/${BlogPost.frontmatter.slug}`} data-augmented-ui="tl-clip br-clip border inlay" className="btn btn-lg text-warning fill-bg px-5 w-100 ">Read More</Link>
                </div>
            </article>
        </div>
    </>)
}

export default BlogPostCard

// nodes {
//     timeToRead
//     excerpt
//     frontmatter {
//       title
//       date
//       description
//       author
//       slug
//       hero {
//         childImageSharp {
//           gatsbyImageData(width: 512, height: 512)
//         }
//       }
//     }
//   }